export default {
    catalog: 'Общий журнал',
    snips: 'Строительные нормы и правила',
    modal: 'Уведомление от T.Pro',
    uploadScheme: 'Загрузить чертеж',
    add: 'Добавить',
    acceptance: 'Приёмка квартир',
    warranty: 'Гарантия',
    callLog: 'Журнал вызовов',
};
