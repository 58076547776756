import { namespace } from 'vuex-class';
import actions from './Actions';
import getters from './Getters';
import mutations from './Mutations';

// Interfaces
import { DesignDrawing } from '@/ship/Models/Design';
import { StoreOptions } from 'vuex';
import VDesigningPlansForm from '@/components/Designing/VDesigningPlansForm.vue';
import VDesigningCanvasPointer from '@/components/Designing/VDesigningCanvasPointer.vue';
import VDesigningIframePointer from '@/components/Designing/VDesigningIframePointer.vue';

export interface PlanTab {
    id: number;
    isOpen: boolean;
    name: string;
    taskId?: number;

    currentDrawing: DesignDrawing | null;
    secondDrawing: DesignDrawing | null;

    planForm: Partial<VDesigningPlansForm> | null;
    mainEditor: Partial<VDesigningCanvasPointer> | null;
    secondEditor: Partial<VDesigningCanvasPointer> | null;
}

export interface ModelTab {
    id: number;
    isOpen: boolean;
    name: string;
    taskId?: number;

    currentDrawing: DesignDrawing | null;

    planForm: Partial<VDesigningPlansForm> | null;
    mainEditor: Partial<VDesigningIframePointer> | null;
}

export interface DesignState {
    designPageLoading: boolean;
    tabsLoading: boolean;
    planTabs: PlanTab[];
    modelTabs: ModelTab[];
}

export const state: DesignState = {
    designPageLoading: false,
    tabsLoading: true,
    planTabs: [],
    modelTabs: [],
};

export const root: StoreOptions<DesignState> = {
    state,
    actions,
    getters,
    mutations,
};

export const DesignStore = namespace('design');
export const DesignAction = DesignStore.Action;
export const DesignMutation = DesignStore.Mutation;
export const DesignState = DesignStore.State;
export const DesignGetter = DesignStore.Getter;

export default root;
