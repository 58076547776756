const Prescription = () => import('@/components/Prescription/Prescription.vue');
const Acceptance = () => import('@/components/Prescription/Acceptance.vue');
const Warranty = () => import('@/components/Prescription/Warranty.vue');
const CallLog = () => import('@/components/Prescription/CallLog.vue');
const PrescriptionListPage = () => import('@/pages/Prescription/PrescriptionListPage.vue');
const PrescriptionHomePage = () => import('@/pages/Prescription/PrescriptionHomePage.vue');
const PrescriptionRequirementsPage = () => import('@/pages/Prescription/PrescriptionRequirementsPage.vue');
const PrescriptionDrawingPage = () => import('@/pages/Prescription/PrescriptionDrawingPage.vue');
const CallLogListPage = () => import('@/pages/Prescription/CallLogListPage.vue');
const PrescriptionPage = () => import('@/pages/PrescriptionPage.vue');
import oidc from '@/vue-oidc-client';

export default [
    {
        name: 'prescription',
        path: '/prescription/',
        component: PrescriptionPage,
        redirect: { name: 'prescription.home' },
        meta: { authName: oidc.authName },
        children: [
            {
                name: 'prescription.home',
                path: '/prescription/main',
                component: PrescriptionHomePage,
                meta: {
                    authName: oidc.authName,
                },
            },

            {
                name: 'prescription.requirements',
                path: '/requirement',
                component: PrescriptionRequirementsPage,
                meta: {
                    authName: oidc.authName,
                },
            },

            {
                name: 'prescription.list',
                path: '/prescription/list',
                component: PrescriptionListPage,
                children: [
                    {
                        name: 'prescription.view',
                        path: 'violation/:violationId',
                        component: Prescription,
                        props: true,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                    {
                        name: 'prescription.create',
                        path: 'create',
                        component: Prescription,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                    {
                        name: 'acceptance.view',
                        path: 'acceptance/violation/:violationId',
                        component: Acceptance,
                        props: true,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                    {
                        name: 'acceptance.create',
                        path: '/prescription/acceptance/create',
                        component: Acceptance,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                    {
                        name: 'warranty.view',
                        path: 'warranty/violation/:violationId',
                        component: Warranty,
                        props: true,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                    {
                        name: 'warranty.create',
                        path: '/prescription/warranty/create',
                        component: Warranty,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                ],
                meta: {
                    authName: oidc.authName,
                },
            },

            {
                name: 'prescription.calllog',
                path: '/prescription/call-log',
                component: CallLogListPage,
                children: [
                    {
                        name: 'calllog.view',
                        path: 'call/:callId',
                        component: CallLog,
                        props: true,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                    {
                        name: 'calllog.create',
                        path: 'create',
                        component: CallLog,
                        meta: {
                            authName: oidc.authName,
                        },
                    },
                ],
                meta: {
                    authName: oidc.authName,
                },
            },

            {
                name: 'prescription.scheme',
                path: '/prescription/scheme',
                component: PrescriptionDrawingPage,
                meta: {
                    authName: oidc.authName,
                },
            },
        ],
    },
];
