var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VGridRow',[_c('VGridCol',{staticClass:"prescriptions-table__wrapper"},[_c('VTable',{staticClass:"calls-table"},[_c('CallsTableHeader'),_c('VTableBody',[_vm._l((_vm.calls),function(call,indexViolation){return _c('VTableRow',{key:indexViolation,ref:"refFullTableRows",refInFor:true,on:{"click":_vm.onOpenSecondaryPage}},[_c('RouterLink',{staticClass:"calls-table__route",class:{ 'calls-table__route--red': _vm.isOverdueStatus(call.status, call.deadline) },attrs:{"to":_vm.route(call.id)}},[_c('VGridCol',[_vm._v(" "+_vm._s(call.id)+" ")]),_c('VGridCol',[_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.dateFormat(call.createdAt, 'dd.mm.yyyy HH:MM'))+" ")])]),_c('VGridCol',[_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.dateFormat(call.deadline, 'dd.mm.yyyy HH:MM'))+" ")])]),_c('VGridCol',[_vm._v(" "+_vm._s(call.project?.data.project)+" ")]),_c('VGridCol',[_vm._v(" "+_vm._s(_vm.getShortName(call.responsible?.data.name))+" ")]),_c('VGridCol',[_vm._v(" "+_vm._s(_vm.getShortName(call.executor?.data.name))+" ")]),_c('VGridCol',{staticClass:"calls-table__status"},[_c('span',{class:{
                                    'calls-table__status--yellow': call.status === _vm.StatusEnum.InWork,
                                    'calls-table__status--green': call.status === _vm.StatusEnum.Accept,
                                    'calls-table__status--purple': call.status === _vm.StatusEnum.Created,
                                    'calls-table__status--red': [
                                        _vm.StatusEnum.Cancelled,
                                        _vm.StatusEnum.Rejected,
                                    ].includes(call.status),
                                }},[_vm._v(" "+_vm._s(_vm.getStatusName(call.status, call.deadline))+" ")])])],1)],1)}),(!_vm.isRequestReady)?_c('VTableRow',{staticClass:"full-table__spinner grid-row_center"},[_c('VSpinner')],1):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }