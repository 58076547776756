import actions from './Actions';
import getters from './Getters';
import mutations from './Mutations';

// Interfaces
import { StoreOptions } from 'vuex';
import User from '@/ship/Models/User';
import IncludeViolation from '@/ship/Models/IncludeViolation';
import IncludeViolationAcceptance from '@/ship/Models/IncludeViolationAcceptance';
import IncludeViolationWarranty from '@/ship/Models/IncludeViolationWarranty';
import IncludeCall from '@/ship/Models/IncludeCall';
import Pagination from '@/ship/Models/Pagination';
import MainPackData from '@/ship/Models/MainPackData';
import ViolationCount from '@/ship/Models/ViolationCount';
import BasicRequirement from '@/ship/Models/BasicRequirement';
import Project from '@/ship/Models/Project';
import Role from '@/ship/Models/Role';
import City from '@/ship/Models/City';
import Position from '@/ship/Models/Position';
import Organisation from '@/ship/Models/Organisation';
import Section from '@/ship/Models/Section';
import Floor from '@/ship/Models/Floor';
import Apartment from '@/ship/Models/Apartment';
import { TranslateResult } from 'vue-i18n';
import { IPMChart } from '@/ship/Models/IProjectManagement';
import { ChartVersion } from '@/ship/Models/ProjectManagement';

export interface RootState {
    user: User | null;
    version: string | null;
    violations: IncludeViolation[] | null;
    pagination: Pagination | null;
    violationsAcceptance: IncludeViolationAcceptance[] | null;
    violationsWarranty: IncludeViolationWarranty[] | null;
    calls: IncludeCall[] | null;
    mainPackData: MainPackData | null;
    violationCount: ViolationCount[] | null;
    lighterViolations: IncludeViolation[] | null;
    requirements: BasicRequirement[] | null;
    projects: Project[] | null;
    users: User[] | null;
    roles: Role[] | null;
    cities: City[] | null;
    positions: Position[] | null;
    organisations: Organisation[] | null;
    sections: Section[];
    floors: Floor[] | null;
    apartments: Apartment[] | null;
    chart: IPMChart | null;
    editChart: IPMChart | null;
    structure: ChartVersion | null;

    appErrorMessage: string | null | TranslateResult;
    isLoadingApp: boolean;
}

export const state: RootState = {
    user: null,
    requirements: null,
    violations: null,
    pagination: null,
    violationsAcceptance: null,
    violationsWarranty: null,
    calls: null,
    mainPackData: null,
    violationCount: null,
    lighterViolations: null,
    projects: null,
    users: null,
    roles: null,
    cities: null,
    positions: null,
    organisations: null,
    sections: [],
    floors: null,
    apartments: null,
    chart: null,
    editChart: null,
    structure: null,

    isLoadingApp: true,
    appErrorMessage: null,

    version: '1.0.0',
};

export const root: StoreOptions<RootState> = {
    state,
    actions,
    getters,
    mutations,
};
export default root;
