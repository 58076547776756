var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationObserver',{staticClass:"structure-form__wrapper",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit: veeSubmitHandler }){return [_c('form',{staticClass:"structure-form",on:{"submit":function($event){$event.preventDefault();return veeSubmitHandler(_vm.handleSubmit)}}},[_c('div',{staticClass:"structure-form__container"},[_c('div',[_c('div',{staticClass:"structure-form__task-title--small"},[_vm._v("Название задачи:")]),_c('div',{staticClass:"structure-form__task-text"},[_vm._v(" "+_vm._s(_vm.activeTask?.name)+" ")]),_c('div',{staticClass:"structure-form__fields structure-form__fields-margin"},[_c('div',{staticClass:"structure-form__field"},[_c('ValidationProvider',{attrs:{"name":"Ответственный"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VFormField',{attrs:{"error-text":errors[0],"label":"Ответственный"}},[_c('VSelect',{attrs:{"options":_vm.users,"reduce":_vm.reduceHandler,"placeholder":"Не выбрано","label":"name"},model:{value:(_vm.model.responsible),callback:function ($$v) {_vm.$set(_vm.model, "responsible", $$v)},expression:"model.responsible"}})],1)]}}],null,true)})],1),(
                            _vm.activeTask?.type === _vm.PMStructureTypeEnum.Task &&
                            _vm.chartType !== _vm.PMChartTypeEnum.consolidated
                        )?_c('div',{staticClass:"structure-form__field"},[_c('ValidationProvider',{attrs:{"name":"% готовности"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VFormField',{attrs:{"error-text":errors[0],"label":"% готовности"}},[_c('VInput',{attrs:{"max":3,"placeholder":"Не выбрано"},on:{"change":_vm.validateReady},model:{value:(_vm.model.ready),callback:function ($$v) {_vm.$set(_vm.model, "ready", $$v)},expression:"model.ready"}})],1)]}}],null,true)})],1):_vm._e(),(
                            _vm.activeTask?.type === _vm.PMStructureTypeEnum.Task &&
                            _vm.chartType !== _vm.PMChartTypeEnum.consolidated
                        )?_c('ul',{staticClass:"structure-form__tags"},[_c('li',{staticClass:"structure-form__tag",on:{"click":function($event){return _vm.updateReady(0)}}},[_vm._v("0%")]),_c('li',{staticClass:"structure-form__tag",on:{"click":function($event){return _vm.updateReady(25)}}},[_vm._v("25%")]),_c('li',{staticClass:"structure-form__tag",on:{"click":function($event){return _vm.updateReady(50)}}},[_vm._v("50%")]),_c('li',{staticClass:"structure-form__tag",on:{"click":function($event){return _vm.updateReady(75)}}},[_vm._v("75%")]),_c('li',{staticClass:"structure-form__tag",on:{"click":function($event){return _vm.updateReady(100)}}},[_vm._v("100%")])]):_vm._e(),(_vm.activeTask?.type === _vm.PMStructureTypeEnum.Task)?_c('div',{staticClass:"structure-form__field"},[_c('ValidationProvider',{attrs:{"name":"Длительность"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VFormField',{attrs:{"error-text":errors[0],"label":"Длительность, рабочих дней"}},[_c('VInput',{attrs:{"max":3,"placeholder":"Не выбрано"},on:{"change":_vm.addComment},model:{value:(_vm.model.duration),callback:function ($$v) {_vm.$set(_vm.model, "duration", $$v)},expression:"model.duration"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.activeTask?.type === _vm.PMStructureTypeEnum.Mark)?_c('div',{staticClass:"structure-form__task-text structure-form__task-text--mt20"},[_vm._v(" Вехи не содержат данных о трудозатратах и имеют нулевую длительность ")]):_vm._e(),(_vm.activeTask?.type === _vm.PMStructureTypeEnum.Stage)?_c('div',{staticClass:"structure-form__task-text structure-form__task-text--mt20"},[_vm._v(" У этапов “Длительность” и “% гот.” вычисляются автоматически ")]):_vm._e()]),(
                        _vm.activeTask?.type !== _vm.PMStructureTypeEnum.Mark &&
                        _vm.activeTask?.startDatePlan &&
                        _vm.activeTask?.endDatePlan
                    )?_c('div',{staticClass:"structure-form__fields"},[_c('div',{staticClass:"structure-form__task-title--big"},[_vm._v("Период ограничения выполнения задачи:")]),_c('div',{staticClass:"structure-form__field"},[_c('ValidationProvider',{attrs:{"name":"Начало периода","rules":{ required: !!_vm.model.periodTo }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VFormField',{attrs:{"error-text":errors[0],"label":"Начало периода"}},[_c('VDatePicker',{attrs:{"error":!!errors[0],"disabled":_vm.disabledPeriod,"max":_vm.activeTask.endDatePlan,"placeholder":_vm.$t('components.select.notSelected')},model:{value:(_vm.model.periodFrom),callback:function ($$v) {_vm.$set(_vm.model, "periodFrom", $$v)},expression:"model.periodFrom"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"structure-form__field"},[_c('ValidationProvider',{attrs:{"name":"Окончание периода","rules":{ required: !!_vm.model.periodFrom }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VFormField',{attrs:{"error-text":errors[0],"label":"Окончание периода"}},[_c('VDatePicker',{attrs:{"error":!!errors[0],"min":_vm.minEndDatePlan,"disabled":_vm.disabledPeriod,"placeholder":_vm.$t('components.select.notSelected')},model:{value:(_vm.model.periodTo),callback:function ($$v) {_vm.$set(_vm.model, "periodTo", $$v)},expression:"model.periodTo"}})],1)]}}],null,true)})],1)]):_vm._e()]),_c('div',{staticClass:"structure-form__buttons"},[_c('VButton',{staticClass:"structure-form__button",attrs:{"color":"primary","type":"submit"}},[_vm._v("Сохранить")])],1),_c('PMStructureAddCommentModal',{ref:"structureAddCommentModal",on:{"updateDuration":_vm.updateDuration,"cancelDurationChange":_vm.cancelDurationChange}})],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }