import { TanglMessageEnum } from '@/ship/Enums/TanglMessageEnum';
import { DesignPoint } from '@/ship/Models/Design';
import DesigningTools from '@/ship/Enums/DesigningTools';

export interface ITanglRemark {
    point: DesignModelPoint;
    cameraTarget?: DesignModelPoint;
    cameraPosition?: DesignModelPoint;
    relatedElementNum?: number;
    remarkData?: { remarkStatus: string; remarkText: string; remarkId: number };
}

export interface ITanglPostMessage {
    type: TanglMessageEnum;
    remarks: ITanglRemark[];
    mousePosition?: DesignPoint;
    currentTool?: DesigningTools;
}

export interface DesignModelPoint extends DesignPoint {
    z: number;
}

export type ITanglMessageHandler = (event: MessageEvent<ITanglPostMessage>) => void;

export class TanglService {
    /**
     * Sets remarks to Tangl 3D scene
     * @param iframe iframe ref with Tangl
     * @param remarks remarks that needs to be added on scene
     */
    public static setRemarksToScene(iframe: HTMLIFrameElement, remarks: ITanglRemark[]): void {
        if (iframe.contentWindow) {
            const message: ITanglPostMessage = {
                type: TanglMessageEnum.AddOnScene,
                remarks,
            };
            iframe.contentWindow.postMessage(message, '*');
        }
    }

    /**
     * Moves camera to position.
     * @param iframe iframe ref with Tangl.
     * @param remark remark with camera position data.
     */
    public static teleportCameraToRemark(iframe: HTMLIFrameElement, remark: ITanglRemark): void {
        if (iframe.contentWindow) {
            const message: ITanglPostMessage = {
                type: TanglMessageEnum.ZoomCamera,
                remarks: [remark],
            };

            iframe.contentWindow.postMessage(message, '*');
        }
    }

    /**
     * Sends a message in Tangl Viewer to switch Tangl mode.
     * @param iframe iframe ref with Tangl.
     * @param cursor current designing editor cursor.
     */
    public static cursorChanged(iframe: HTMLIFrameElement, cursor: DesigningTools) {
        if (iframe.contentWindow) {
            const message: ITanglPostMessage = {
                type: TanglMessageEnum.ToolChanged,
                remarks: [],
                currentTool: cursor,
            };

            iframe.contentWindow.postMessage(message, '*');
        }
    }
}
